import React from 'react'
import Lottie from 'react-lottie'
import './landingPage.css'

export default class LandingPage extends React.Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: require('../../animations/me_at_office.json'),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    return (
      <div className="landing-page-container">
        <span>work in progress</span>
        <Lottie options={defaultOptions} height={500} width={500} />
      </div>
    )
  }
}
